.sidebar {
    padding-top: 1rem;

    &-section {
        padding-bottom: 2rem;
    }
    &-subtitle {
        margin: 0;
        color: rgba($primary-dark,0.5);
        text-transform: capitalize;
    }
    &-title {
        margin: 0;
        font-size: 1.3rem;
    }
}