// font imports
@font-face {
    font-family: 'Wavehaus';
    src: url("../../assets/fonts/graham-paterson-wavehaus-typeface/Wavehaus-95SemiBold.otf") format('opentype');
}
@import url(https://fonts.googleapis.com/css2?family=Be+Vietnam:ital,wght@0,400;0,500;1,400;1,500&display=swap);
// fonts
$header-font: 'Wavehaus', sans-serif;
$body-font: 'Be Vietnam', sans-serif;

// colors
$primary-dark: #133B51;
$primary-light: #FFFFFF;

$accent-1: #92F9F3;
$accent-2: #FD643D;
$accent-3: #FEC44A;

// body colors
body {
    background: $primary-light;
    color: $primary-dark;
}

// type scale
h1, h2, h3, h4, h5, h6 {
    font-family: $header-font;
}

h1 {
    font-size: 60px;
    line-height: 72px;
}

h2 {
    font-size: 34px;
    line-height: 44px;
}

p, a {
    font-family: $body-font;
}

a {
    &:hover {
        color: inherit;
    }
}

:export {
    primaryLight: $primary-light;
    primaryDark: $primary-dark;

    accent1: $accent-1;
    accent2: $accent-2;
    accent3: $accent-3;
  }
  