.events {
    padding-top: 10%;

    &-conference {
        img {
            width: 100%;
            border-radius: 1rem;
            &:hover {
                transform: scale(1.01);
            }
        }
    }

    &-section {
        padding-bottom: 4rem;

    }

    &-name {
        .cta {
            span {
                font-size: 2rem;
            }
            svg {
                position: relative;
                top: 2px;
                margin-left: 20px;
                fill: none;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke: $primary-dark;
                stroke-width: 4;
                transform: translateX(-5px);
                transition: all .3s ease;
            }
            &:hover {
                a {
                    text-decoration: none;
                }
                &:before {
                width: 100%;
                background: rgba($accent-1,1);
                }
                svg {
                transform: translateX(0);
                }
            }
        }
    }

    &-date {
        font-weight: 900;
    }

    &-container {
        padding: 1rem 0;
    }

    &-card {
        padding: 1rem; 
        width: 100%;
        height: inherit; 

    }

    &-image {
        width: 100%;
        img {
            width: inherit;
            border-radius: 1rem;           
        }
        &-hover {
            &:hover{
            transform: scale(1.01);
            }
        }
    }

    /*
    &-buttons {
        padding: 1rem 0;
    }
    */
}