.involvement {
    padding-top: 10%;

    &-intro {
        padding-bottom: 4rem;
    }

    &-card {
        padding-bottom: 3rem;

        &-button {
            padding: 1rem 0;
        }
    }
}