@import "../base/base";
// Variable overrides
$primary: $primary-dark;
$secondary: $primary-dark;

$body: $primary-dark;

@import "../../../node_modules/bootstrap/scss/bootstrap";

// Set nav-link styles
.nav-link {
    font-weight: bold; // Set to bold by default
    text-decoration: none; // Ensure no underline by default

    &:hover {
        text-decoration: underline; // Underline on hover
    }
}

// New styles for the active link using the updated class name
.nav-link.active-link {
    text-decoration: underline; // Keep underlined for the active page
}

// Optional: Keep this if you want standard links to have no underline on hover
a {
    &:hover {
        text-decoration: none; // Keep this if you want links to have no underline
    }
}
