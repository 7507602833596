footer {
    font-family: $header-font;
    i:hover {
            border-radius: 20px;
            scale: 1.2;
            background: rgba(146, 249, 243, 0.5);
            transition: all .3s ease;
    }
    a {
        font-family: inherit;
    }
}