.navbar {
    background-color: $primary-light;
    color: $primary-dark;
    height: auto;
    z-index: 100;

    .navbar-brand {
        display: block;
        margin: auto;

        img {
            height: 50px;
        }

        .nav-link {
            font-weight: bold; // Set to bold by default
            text-decoration: none; // Ensure no underline by default

            &:hover {
                text-decoration: underline; // Underline on hover
            }
        }
    }
    .menu {
        margin: auto;
    }

    li {
        display: inline;
    }
}

@include media-md {
    .navbar {
        height: 76px;

        .navbar-brand {
            display: inline-block;
            margin: 0 1rem 0 0; 
        }

        .menu {
            margin: 0;
        }
    }
}
